import React from "react";
import logo from "./logo.svg";
import "./App.css";
import "@fontsource/roboto/300.css";
import TextField from "@mui/material/TextField";
import B64Video from "./B64Video";

import Art from "./assets/art-crop.mp4";
import firebaseAppendWallet from "./Firebase";
// import Art from "./assets/art.gif";

// const useStyles = makeStyles(() => ({
//   noBorder: {
//     border: "none",
//   }
// }));

function App() {
  const [wallet, setWallet] = React.useState<string>();

  // const classes = useStyles();

  return (
    <div
      className="App"
      style={{
        backgroundColor: "black",
        width: "100wh",
        height: "100vh",
        color: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          marginTop: "3em",
          // display: "flex",
          // alignItems: "ce"
        }}
      >
        <video
          autoPlay={true}
          loop
          muted
          style={{
            height: "55vh",
            maxWidth: "95%",
          }}
        >
          {/* <source src={Art} type="video/mp4" /> */}
          {/* Generated using `cat art-crop.mp4 | base64 -w 0 > ../B64Video.tsx`, then adding js around the b64 blob */}
          <B64Video />
        </video>
        {/* <img src={Art}/> */}
        <div style={{ fontFamily: "Roboto", fontSize: 16, maxWidth: "80vw", margin: "auto", alignSelf: "center", }}>
          <div>
            <i>Ripples:</i> An exclusive NFT airdrop for the first 333{" "}
            <a style={{ color: "white" }} href="https://endangeredsounds.art">
              <i>Endangered Soundprint</i>
            </a>
            {" holders"}
          </div>
        </div>
        <div style={{ fontFamily: "Roboto", fontSize: 16 }}>
          {"~100 eligible Soundprint mints remaining"}
        </div>
      </div>
      <div style={{ fontFamily: "Roboto", fontSize: 14 }}>
        {wallet && wallet.length == 44 ? (
          <div style={{ marginBottom: "5em", maxWidth: "80vw" }}>
            Thankyou, we will announce the start of the <i>Ripples</i> airdrop on{" "}
            <a
              style={{ color: "white" }}
              href="https://twitter.com/EndangeredSol"
            >
              twitter
            </a>{" "}
            in the coming days.
          </div>
        ) : (
          <div>
            <div style={{maxWidth: "80vw", margin: "auto"}}>
              Recieve one <i>Ripples</i> for each <i>Endangered Soundprint</i>{" "}
              in your wallet:
            </div>
            <TextField
              label="Wallet Id"
              size="small"
              // className={classes.TextField}
              sx={{
                color: "white",
                backgroundColor: "#555",
                borderRadius: 40,
                marginTop: "1.5em",
                marginBottom: "4em",
                width: 400,
                maxWidth: "90vw",
                fontSize: 10,
              }}
              InputProps={{
                style: { color: "white", fontSize: 14 },
              }}
              InputLabelProps={{
                style: { color: "#DDD", fontSize: 14, marginLeft: "1em" },
              }}
              onChange={(e) => {
                let id = e.target.value.trim();

                if (id.length == 44) {
                  firebaseAppendWallet(id);
                }
                setWallet(id);
              }}
              value={wallet}
            />
          </div>
        )}

        {/* <input
          id="textInput"
          style={{
            backgroundColor: "#222222",
            height: 30,
            borderRadius: 20,
            color: "white",
          }}
          type="text"
          placeholder="wallet id"
        >
          {wallet}
        </input> */}
      </div>
    </div>
  );
}

export default App;
