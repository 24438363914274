// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getFirestore,
  collection,
  setDoc,
  getDoc,
  doc,
  addDoc,
} from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB3MOwXK0Ih7E28SIKi2sWuLi6zTfy9xOo",
  authDomain: "endangered-7b3b0.firebaseapp.com",
  projectId: "endangered-7b3b0",
  storageBucket: "endangered-7b3b0.appspot.com",
  messagingSenderId: "122784858396",
  appId: "1:122784858396:web:e71cc5a271ca5c79777ad9",
  measurementId: "G-H0VCXNL4JW",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const firebaseAppendWallet = (wallet_id: string) => {
  const db = getFirestore();

  // db.collection()

  setDoc(doc(db, "ripples-wallets", wallet_id), {});

  // // const doc = getDoc(collection(db, "ripples-wallets"), )
  // const wallet_doc = doc()
  // setDoc(collection(db, "ripples-wallets"), {});
  // addDoc()
};

export default firebaseAppendWallet;
